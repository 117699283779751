import * as React from 'react';

export const LogoWithIcons: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.55 55.55">
    <g>
      <path
        d="M17.88 55h56.8a2.26 2.26 0 002.3-2.3V51H19"
        fill="#fff"
        stroke="#ccc"
        strokeWidth="1.14px"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M59.68 55H3.08a2.43 2.43 0 01-2.4-2.4V51h58.1"
        fill="#fff"
        stroke="#ccc"
        strokeWidth="1.14px"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16.48 51h54.4V14.38a3.36 3.36 0 00-3.5-3.2h-56.2a3.3 3.3 0 00-3.5 3.2V51z"
        fill="#eaecef"
        strokeLinecap="round"
        strokeMiterlimit="10"
        stroke="#ccc"
        strokeWidth="1.14"
      />
      <path d="M12.38 17.08h52.9v33h-52.9z" fill="#fff" />
      <path
        fill="#fff"
        stroke="#00baff"
        strokeWidth=".76px"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M12.08 39.88H1.58a1.22 1.22 0 01-1.2-1.2v-6.8a1.22 1.22 0 011.2-1.2h10.6a1.22 1.22 0 011.2 1.2v6.8a1.3 1.3 0 01-1.3 1.2z"
      />
      <path
        fill="#fff"
        stroke="#00baff"
        strokeWidth=".76px"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M.58 31.28l5.3 3.8a1.35 1.35 0 001.7 0l5.4-3.8M63.48 10l1.9 1.1a.75.75 0 010 1.3l-1.9 1.1a.74.74 0 01-1.1-.7v-2.2c-.1-.6.62-.92 1.1-.6zm6.8 2.6v-1.8c-.9-.3-1.5-.4-1.8-1.1-.3-.7.1-1.2.5-2.1l-1.3-1.3c-.9.4-1.4.8-2.1.5-.7-.3-.8-.9-1.1-1.8h-1.8c-.3.9-.4 1.5-1.1 1.8-.7.3-1.2-.1-2.1-.5l-1.3 1.3c.4.9.8 1.4.5 2.1s-.9.8-1.8 1.1v1.8c.9.3 1.5.4 1.8 1.1s-.1 1.2-.5 2.1l1.3 1.3c.9-.4 1.4-.8 2.1-.5.7.3.8.9 1.1 1.8h1.8c.3-.9.4-1.5 1.2-1.8.7-.3 1.2.1 2.1.5l1.1-1.2c-.4-.9-.8-1.4-.5-2.1s1-.8 1.9-1.22zM22.68 19.08h-8.4a1.9 1.9 0 01-1.9-1.9v-9.1a1.9 1.9 0 011.9-1.9h8.4a1.9 1.9 0 011.9 1.9v9.1a1.84 1.84 0 01-1.9 1.9zm-10.2-7.6h12.1m-6.1 0v7.6M38.28 10.08v3.1m-2.6 0H41m-2.7-9.6a1.6 1.6 0 101.6 1.6 1.58 1.58 0 00-1.62-1.6zm0-3.2a4.9 4.9 0 104.9 4.9 4.91 4.91 0 00-4.92-4.9zM61 27.38h2.2c.8 0 1 .9 1.1 1.1s2 8.4 2 8.4.2 1.1 1.1 1.1h7.3c.9 0 1.1-1.1 1.1-1.2.2-.8 1.2-5.3 1.2-5.3s.2-1-1.1-1h-11"
      />
      <circle
        cx="74.18"
        cy="40.68"
        r="1.1"
        fill="#fff"
        stroke="#00baff"
        strokeWidth=".76px"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <circle
        cx="68.18"
        cy="40.68"
        r="1.1"
        fill="#fff"
        stroke="#00baff"
        strokeWidth=".76px"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M49.58 39.78a.9.9 0 01-.9.9h-18.4a.9.9 0 01-.9-.9v-11.1a.9.9 0 01.9-.9h18.4a.9.9 0 01.9.9z"
        fill="#00adee"
      />
      <path
        fill="#fff"
        d="M49.38 28.08c-2.4 3.7-6.1 7.1-9.9 7a7.09 7.09 0 01-4.4-1.5 6.85 6.85 0 01-2.4-4h-.4a.9.9 0 00-.9 1.1.76.76 0 00.1.4 8.42 8.42 0 003.1 5.1 8.6 8.6 0 005 1.7c3.4 0 7-2.1 10.1-7.6v-1.6z"
      />
    </g>
  </svg>
);
