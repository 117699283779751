import styled from 'styled-components';

export interface ILanguagesListWrapperProps {
  top: number;
  left: number;
  iconSideBorderWidth: number;
  iconLeft: number;
}

export const LanguagesListWrapper = styled.div<ILanguagesListWrapperProps>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;

  &::before {
    position: absolute;
    content: '';
    transform: translate(0, -100%);
    left: ${({ iconLeft }) => iconLeft}px;
    top: 0;
    width: 0;
    height: 0;
    border-right: ${({ iconSideBorderWidth }) => iconSideBorderWidth}px solid transparent;
    border-left: ${({ iconSideBorderWidth }) => iconSideBorderWidth}px solid transparent;
    border-bottom: 5px solid rgba(16, 22, 29, 0.9);
  }
`;
