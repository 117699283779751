import styled from 'styled-components';
import * as background from '../assets/background.png';

export const PageWrapper = styled.div`
  background-image: url(${window.__WEBINAR.backgroundImage ?? background.src});
  background-color: #d6d6cf;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .whitelabel-page {
    min-height: 476px;
  }
`;
