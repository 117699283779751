import * as React from 'react';

import { initTranslations } from '../utils/translations';

type WebinarLangHook = {
  loaded: boolean;
  loading: boolean;
  lang: string;
  fetchTranslations: (language: string) => void;
  handleLanguage: (language: string) => void;
};

export const useWebinarLang = (currentLanguage: string, langList: string[] = []): WebinarLangHook => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [lang, setLang] = React.useState<string>(currentLanguage);

  const loaded = !loading;

  const fetchTranslations = React.useCallback(
    (language: string) => {
      setLoading(true);

      initTranslations(language, langList).then(() => {
        setLoading(false);
      });
    },
    [setLoading, langList],
  );

  const handleLanguage = React.useCallback(
    (language: string) => {
      setLang(language);
    },
    [setLang],
  );

  return {
    loaded,
    loading,
    lang,
    fetchTranslations,
    handleLanguage,
  };
};
