import * as React from 'react';
import * as throttle from 'lodash.throttle';

import { LanguageElement } from './LanguageElement';

import { Portal, GlobeIcon, LanguageSelectWrapper, LanguagesList, LanguagesListWrapper, SelectedLanguage } from './';

import { mobileBreakPoint } from '../constants/mobileBreakPoints';

const LANGUAGES_LIST_WIDTH = 140;
const ICON_SIDE_BORDER_WIDTH = 6;
const GLOBE_INNER_WIDTH = 15;

interface IState {
  isLanguagesMenuOpen: boolean;
  langGlobeBottom: number;
  langGlobeLeft: number;
  langGlobeWidth: number;
  isMobileView: boolean;
}

interface ILanguageSelectProps {
  selectedLanguage: string;
  changeLanguage: (lang: string) => void;
  fetchTranslations: (lang: string) => void;
  languages: string[];
  dataAtsKey: string;
}

export class LanguageSelect extends React.PureComponent<ILanguageSelectProps, IState> {
  private readonly throttledUpdateGlobeIconPosition: () => void;
  private readonly throttledHandleResize: () => void;

  public state: IState = {
    isLanguagesMenuOpen: false,
    langGlobeBottom: undefined,
    langGlobeLeft: undefined,
    langGlobeWidth: undefined,
    isMobileView: window.innerWidth < mobileBreakPoint,
  };

  public parentRef: React.RefObject<HTMLSpanElement> = React.createRef();

  constructor(props: ILanguageSelectProps) {
    super(props);
    const THROTTLE_DURATION = 300;
    this.throttledUpdateGlobeIconPosition = throttle(this.updateGlobeIconPosition, THROTTLE_DURATION);
    this.throttledHandleResize = throttle(this.handleResize, THROTTLE_DURATION);
  }

  public componentDidMount(): void {
    const { scrollTop } = document.scrollingElement || document.documentElement;
    const parentNodeRect = this.getParentNodeRect();
    // TODO: remove eslint suppression
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      langGlobeBottom: parentNodeRect.bottom + scrollTop,
      langGlobeLeft: parentNodeRect.left,
      langGlobeWidth: parentNodeRect.width,
    });
    window.addEventListener('resize', this.throttledHandleResize);
  }

  public componentDidUpdate(): void {
    const { isLanguagesMenuOpen } = this.state;

    this.throttledUpdateGlobeIconPosition();

    if (isLanguagesMenuOpen) {
      window.addEventListener('resize', this.throttledUpdateGlobeIconPosition);
    } else {
      window.removeEventListener('resize', this.throttledUpdateGlobeIconPosition);
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.throttledHandleResize);
  }

  public handleResize = (): void => {
    this.setState({
      isMobileView: window.innerWidth < mobileBreakPoint,
    });
  };

  public handleToggleLanguagesMenu = (): void => {
    this.setState((prevState) => ({
      isLanguagesMenuOpen: !prevState.isLanguagesMenuOpen,
    }));
  };

  public handleCloseLanguagesMenu = (): void => {
    this.setState({
      isLanguagesMenuOpen: false,
    });
  };

  public getParentNodeRect = (): ClientRect => {
    const { current: parentNode } = this.parentRef;
    let parentNodeRect = {} as ClientRect;

    if (parentNode) {
      parentNodeRect = parentNode.getBoundingClientRect();
    }

    return parentNodeRect;
  };

  public updateGlobeIconPosition = (): void => {
    const { scrollTop } = document.scrollingElement || document.documentElement;
    const parentNodeRect = this.getParentNodeRect();
    this.setState({
      langGlobeBottom: parentNodeRect.bottom + scrollTop,
      langGlobeLeft: parentNodeRect.left,
    });
  };

  public render(): React.ReactNode {
    const { selectedLanguage, fetchTranslations, dataAtsKey } = this.props;
    const { isLanguagesMenuOpen, langGlobeBottom, langGlobeLeft, langGlobeWidth, isMobileView } = this.state;
    const languagesListPositionLeft: number = isMobileView
      ? langGlobeLeft - LANGUAGES_LIST_WIDTH / 2
      : langGlobeLeft - LANGUAGES_LIST_WIDTH / 2 + langGlobeWidth / 2;
    const iconPositionLeft: number = isMobileView
      ? LANGUAGES_LIST_WIDTH / 2 + GLOBE_INNER_WIDTH / 2
      : LANGUAGES_LIST_WIDTH / 2 - ICON_SIDE_BORDER_WIDTH;

    return (
      <React.Fragment>
        <LanguageSelectWrapper
          onClick={this.handleToggleLanguagesMenu}
          {...{
            [`data-ats-${dataAtsKey}`]: 'languages-menu',
          }}
        >
          <span ref={this.parentRef}>
            <GlobeIcon name="globeEu" size="medium" />
          </span>
          <SelectedLanguage>{selectedLanguage}</SelectedLanguage>
        </LanguageSelectWrapper>
        <Portal
          isOpen={isLanguagesMenuOpen}
          closeOnEsc={true}
          closeOnOutsideClick={true}
          onClose={this.handleCloseLanguagesMenu}
        >
          <LanguagesListWrapper
            top={langGlobeBottom}
            left={languagesListPositionLeft}
            iconSideBorderWidth={ICON_SIDE_BORDER_WIDTH}
            iconLeft={iconPositionLeft}
          >
            <LanguagesList
              width={LANGUAGES_LIST_WIDTH}
              {...{
                [`data-ats-${dataAtsKey}-selected-language`]: selectedLanguage,
              }}
            >
              {this.props.languages.map((lang) => (
                <LanguageElement
                  key={lang}
                  lang={lang.toUpperCase()}
                  selectedLanguage={selectedLanguage}
                  handleLanguageChange={this.props.changeLanguage}
                  onCloseLanguagesMenu={this.handleCloseLanguagesMenu}
                  fetchTranslations={fetchTranslations}
                  dataAtsKey={dataAtsKey}
                />
              ))}
            </LanguagesList>
          </LanguagesListWrapper>
        </Portal>
      </React.Fragment>
    );
  }
}
