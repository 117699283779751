import styled from 'styled-components';
import { mobileBreakPoint } from '../constants/mobileBreakPoints';

type PageContainerProps = {
  passwordPage?: boolean;
};

export const PageContainer = styled.div<PageContainerProps>`
  position: relative;
  padding: 65px 20px 60px;
  min-height: 250px;

  @media (min-width: ${mobileBreakPoint}px) {
    padding: 65px 50px 60px;
  }

  ${(props) =>
    props.passwordPage &&
    `
      min-height: 400px;
  `}
`;
