import styled from 'styled-components';
import { FormField, FormFieldProps } from '@gr/ui';

interface StyledFormFieldProps extends FormFieldProps {
  contentAlign?: 'left' | 'center' | 'right';
  contentLine?: boolean;
}

export const StyledFormField = styled(FormField)<StyledFormFieldProps>`
  margin-bottom: 20px;

  ${(props) =>
    props.contentLine &&
    `
        padding: 0 !important;
    `}

  ${(props) => props.contentAlign && `text-align: ${props.contentAlign};`}
`;
