import styled from 'styled-components';
import { ITheme } from '../styles/theme';

export const SelectedLanguage = styled.span<{ theme?: ITheme }>`
  font-size: ${({ theme }) => theme.font.size.small}px;
  width: 15px;
  height: 27px;
  line-height: 27px;
  text-decoration: underline;
  text-transform: uppercase;
  user-select: none;
`;
