import styled from 'styled-components';
import { Icon, IconProps } from '@gr/ui';
import { ITheme } from '../styles/theme';

export const GlobeIcon = styled(Icon)<IconProps & { theme?: ITheme }>`
  width: 27px;
  height: 27px;
  & svg {
    fill: ${({ theme }) => theme.color.icon.primary};
    stroke: none;
  }
`;
