import styled from 'styled-components';

export enum LabelRequiredPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

type LabelRequiredProps = {
  position?: LabelRequiredPosition;
};

export const LabelRequired = styled.span<LabelRequiredProps>`
  &:before,
  &:after {
    color: ${(props) => props.theme.color.status.error};
  }

  ${(props) =>
    props.position === LabelRequiredPosition.LEFT &&
    `
        &:before {
            content: '*';
            margin-right: 4px;    
        }
    `}

  ${(props) =>
    props.position === LabelRequiredPosition.RIGHT &&
    `
        &:after {
            content: '*';
            margin-left: 4px;
        }
    `}
`;

LabelRequired.defaultProps = {
  position: LabelRequiredPosition.LEFT,
};
