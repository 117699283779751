import styled, { css } from 'styled-components';
import { ITheme } from '../styles/theme';

export interface ILanguageElementWrapperProps {
  isLanguageSelected: boolean;
  onClick: () => void;
}

export const LanguageElementWrapper = styled.button<ILanguageElementWrapperProps & { theme?: ITheme }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 56px;
  padding: 4px 16px 4px 6px;
  margin: 2px;
  border-radius: ${({ theme }) => theme.borderRadius.primary}px;
  ${({ isLanguageSelected }: ILanguageElementWrapperProps) =>
    isLanguageSelected &&
    css`
      background: ${({ theme }) => theme.color.background.deep};
    `}
  :hover {
    background: ${({ theme }) => theme.color.background.deep};
  }
`;
