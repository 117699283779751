import * as React from 'react';
import { Portal as ReactPortal } from 'react-portal';

import noop from '../utils/noop';

const ESCAPE = 27;

export type PortalProps = {
  onOpen?: () => void;
  isOpen?: boolean;
  closeOnEsc?: boolean;
  closeOnOutsideClick?: boolean;
  onClose?: () => void;
  className?: string;
  children: React.ReactNode;
};

export class Portal extends React.PureComponent<PortalProps> {
  contentContainer = React.createRef<HTMLDivElement>();

  static defaultProps = {
    isOpen: false,
    onOpen: noop,
    closeOnEsc: false,
    closeOnOutsideClick: false,
    onClose: noop,
  };

  componentDidMount(): void {
    const { isOpen, onOpen } = this.props;

    if (isOpen) {
      onOpen();
    }

    document.addEventListener('keydown', this.handleKeyDown.bind(this), true);
    document.addEventListener('click', this.handleClick.bind(this), true);
  }

  componentDidUpdate({ isOpen: prevIsOpen }: PortalProps): void {
    const { isOpen, onOpen } = this.props;

    if (isOpen && prevIsOpen !== isOpen) {
      onOpen();
    }
  }

  componentWillUnmount(): void {
    const { isOpen, onClose } = this.props;
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
    document.removeEventListener('click', this.handleClick.bind(this));

    if (isOpen) {
      onClose();
    }
  }

  handleKeyDown(event: React.KeyboardEvent): void {
    const { isOpen, closeOnEsc, onClose } = this.props;

    if (!isOpen || !closeOnEsc || event.keyCode !== ESCAPE) {
      return;
    }

    onClose();
  }

  handleClick(event: React.MouseEvent): void {
    const { isOpen, closeOnOutsideClick, onClose } = this.props;

    if (!isOpen || !closeOnOutsideClick || event.button !== 0) {
      return;
    }
    const { current: containerNode } = this.contentContainer;

    if (containerNode && (event.target === containerNode || containerNode.contains(event.target as Element))) {
      return;
    }

    onClose();
  }

  render(): React.ReactNode {
    const { isOpen, children, className } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <ReactPortal>
        <div ref={this.contentContainer} className={className}>
          {children}
        </div>
      </ReactPortal>
    );
  }
}
