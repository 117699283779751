import styled from 'styled-components';
import { PageHeader, PageHeaderProps } from '@gr/ui';
import { mobileBreakPoint } from '../constants/mobileBreakPoints';

type StyledPageHeaderProps = PageHeaderProps & {
  asTitle?: boolean;
};

export const StyledPageHeader = styled<StyledPageHeaderProps>(PageHeader)`
  margin: 35px 0 42px;
  padding: 0;

  ${(props) =>
    props.asTitle &&
    `
    margin-bottom: 10px;
  `}

  @media (min-width: ${mobileBreakPoint}px) {
    padding: 0 42px;
  }
`;
