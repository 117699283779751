import * as superagent from 'superagent';

interface IResponseHeaders {
  'content-length': string;
  currentpage: string;
  totalcount: string;
  totalpages: string;
  [key: string]: string;
}

export enum RequestMethods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}
export type RequestAgent = superagent.SuperAgentRequest;
export type IResponse<T = object> = [T, IResponseHeaders];

const DEFAULT_HEADERS = {
  Accept: 'application/json',
};

export const request = (method: RequestMethods, url: string): superagent.SuperAgentRequest =>
  superagent(method, url).set(DEFAULT_HEADERS);
