import styled from 'styled-components';
import { ITheme } from '../styles/theme';

export const StyledHeading = styled.h4<{ theme?: ITheme; asSubtitle?: boolean }>`
  color: ${({ theme }) => theme.color.text.black};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  font-size: 20px;
  margin-bottom: 11px;
  letter-spacing: 0.012em;

  ${(props) =>
    props.asSubtitle &&
    `
    text-align: center;
  `}
`;
