import styled from 'styled-components';

import { mobileBreakPoint } from '../constants/mobileBreakPoints';

import { ContentContainer } from './ContentContainer';

export const InfoContainer = styled(ContentContainer)`
  margin: 100px 52px;

  @media (min-width: ${mobileBreakPoint}px) {
    margin: 100px 0px;
  }
`;
