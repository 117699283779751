import styled from 'styled-components';
import { mobileBreakPoint } from '../constants/mobileBreakPoints';

export const FeaturesImage = styled.span`
  svg {
    display: none;

    @media (min-width: ${mobileBreakPoint}px) {
      display: block;
      min-width: 158px;
      max-width: 158px;
      height: auto;
    }
  }
`;
