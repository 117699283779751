import styled from 'styled-components';
import { Icon, IconProps } from '@gr/ui';

export const CheckIcon = styled(Icon)<IconProps>`
  position: absolute;
  right: 3px;
  width: 14px;
  height: 14px;
  & svg {
    stroke-width: 3px;
  }
`;
