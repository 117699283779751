import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  forceRefresh:
    typeof window !== 'undefined' && typeof window['__LANG_RELOAD'] !== 'undefined'
      ? window.window['__LANG_RELOAD']
      : true,
});

export default history;
