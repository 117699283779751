import styled from 'styled-components';
import { ITheme } from '../styles/theme';

export const SubHeader = styled.h2<{ theme?: ITheme }>`
  text-align: center;
  font-size: ${(props) => props.theme.font.size['x-large']}px;
  font-weight: ${(props) => props.theme.font.weight.regular};

  color: ${(props) => props.theme.color.text.moderate};
`;
