import * as React from 'react';
import { CheckIcon, LanguageElementWrapper } from './';
import history from '../utils/history';
import { setCookie } from '../utils/cookie';

interface ILanguageElementProps {
  lang: string;
  selectedLanguage: string;
  handleLanguageChange: (lang: string) => void;
  onCloseLanguagesMenu: () => void;
  fetchTranslations: (lang: string) => void;
  dataAtsKey: string;
}

export class LanguageElement extends React.PureComponent<ILanguageElementProps> {
  public handleLanguageChange = async (): Promise<void> => {
    const { lang, handleLanguageChange, onCloseLanguagesMenu, fetchTranslations } = this.props;
    const langLowerCase = lang.toLowerCase();
    const langParam = `?lang=${langLowerCase}`;

    onCloseLanguagesMenu();

    await fetchTranslations(langLowerCase);

    handleLanguageChange(lang);

    // Update localstorage
    setCookie('lang', langLowerCase, 1, history.location.pathname);

    history.push(history.location.pathname + langParam);
  };

  public render(): React.ReactNode {
    const { lang, selectedLanguage, dataAtsKey } = this.props;
    const isLanguageSelected: boolean = lang === selectedLanguage;

    return (
      <LanguageElementWrapper
        onClick={this.handleLanguageChange}
        isLanguageSelected={isLanguageSelected}
        {...{
          [`data-ats-${dataAtsKey}-language`]: lang,
        }}
      >
        {lang}
        {isLanguageSelected ? <CheckIcon name="check" size="small" /> : null}
      </LanguageElementWrapper>
    );
  }
}
