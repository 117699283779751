import { getCookie } from './cookie';

export const DEFAULT_LANGUAGE = 'en';

export const getCurrentLanguage = (): string => {
  const savedLang = getCookie('lang');
  let currentLang = DEFAULT_LANGUAGE;
  let availableLang = ['en'];
  let webinar = null;

  if (typeof window !== 'undefined') {
    // Selected webinar
    if (window.__WEBINAR) {
      webinar = window.__WEBINAR;
    }

    // List of available languages
    if (window.__LANGUAGES) {
      availableLang = window.__LANGUAGES;
    }

    if (savedLang && availableLang.includes(savedLang)) {
      currentLang = savedLang;
    } else if (webinar && availableLang.includes(webinar.languageCode)) {
      currentLang = webinar.languageCode;
    }

    // Currently selected language (by ?lang param)
    if (window.__CURRENT_LANGUAGE) {
      currentLang = window.__CURRENT_LANGUAGE;
    }
  }

  return currentLang;
};
