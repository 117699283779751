import { bootstrap as core, initSentry } from '@gr/core';
import { initTranslations } from './translations';

export async function bootstrap(locale: string, translationSpaces: string[], cb: () => void): Promise<void> {
  const PRODUCTION = process.env.NODE_ENV === 'production';
  initSentry({
    dsn: 'https://fa771ee853254efda91ff41d443dbbb0@o566338.ingest.sentry.io/5879228',
    release: process.env.GIT_SHA,
  });
  try {
    await Promise.all([
      core({
        gtm: {
          enabled: PRODUCTION,
          gtmId: 'GTM-T3PMRBX',
        },
        vwo: {
          enabled: PRODUCTION,
        },
      }),
      initTranslations(locale, translationSpaces),
    ]);
    cb();
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('bootstrap failed', e);
    }
    cb();
  }
}
