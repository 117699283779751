import styled from 'styled-components';
import { mobileBreakPoint } from '../constants/mobileBreakPoints';

export const ContentContainer = styled.div`
  margin: 0;

  @media (min-width: ${mobileBreakPoint}px) {
    margin: 0 52px;
  }
`;
