import styled from 'styled-components';
import { ITheme } from '../styles/theme';

export const Info = styled.small<{ theme?: ITheme }>`
  font-family: ${(props) => props.theme.font.family.primary};
  font-size: ${(props) => props.theme.font.size.medium}px;
  font-weight: ${(props) => props.theme.font.weight.regular};

  color: ${(props) => props.theme.color.text.light};
`;
