const DAY = 24;
const HOUR = 3600;
const SECOND = 1000;

export const setCookie = (name: string, value: string | number, expireDays: number = 1, path: string = '/'): void => {
  const date = new Date();
  date.setTime(date.getTime() + expireDays * DAY * HOUR * SECOND);

  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${name}=${value};${expires};path=${path}`;
};

export const getCookies = (): Record<string, string> =>
  document.cookie
    .split(';')
    .map((elem) => elem.split('='))
    .reduce((previous, current) => {
      previous[current[0]] = current[1];

      return previous;
    }, {});

export const getCookie = (name: string): string | null => getCookies()[name] || null;
