import * as React from 'react';

import { GETRESPONSE_COM_URL } from '../constants/urls';

import { BadgeWrapper } from './BadgeWrapper';
import { GRBadgeWrapper } from './GRBadgeWrapper';
import { StyledLogoImage } from './StyledLogoImage';
import { GRLogo } from './GRLogo';

type LogoProps = {
  whiteLabel: boolean;
  logoUrl: string;
};

export function Logo({ whiteLabel, logoUrl }: LogoProps): JSX.Element {
  if (whiteLabel) {
    if (logoUrl) {
      return (
        <BadgeWrapper>
          <StyledLogoImage src={logoUrl} />
        </BadgeWrapper>
      );
    }

    return null;
  }

  return (
    <GRBadgeWrapper>
      <a href={GETRESPONSE_COM_URL} target="_self" rel="noreferrer nofollow noopener" data-ats-page="logo">
        <GRLogo />
      </a>
    </GRBadgeWrapper>
  );
}

Logo.displayName = 'Logo';
