import styled from 'styled-components';
import { ITheme } from '../styles/theme';

export const LanguageSelectWrapper = styled.a<{ theme?: ITheme }>`
  position: absolute;
  top: 14px;
  right: 19px;
  color: ${({ theme }) => theme.color.text.strong};
  display: flex;
  align-items: center;
  :hover {
    & svg {
      fill: ${({ theme }) => theme.color.link.active};
    }
  }
`;
