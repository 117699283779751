import styled from 'styled-components';
import { ITheme } from '../styles/theme';
import { mobileBreakPoint } from '../constants/mobileBreakPoints';

export const FeaturesList = styled.ul<{ theme?: ITheme }>`
  list-style-type: disc;
  line-height: 23px;
  padding: 0 0 0 18px;
  color: ${({ theme }) => theme.color.text.deep};

  @media (min-width: ${mobileBreakPoint}px) {
    padding: 0 35px 0 18px;
  }
`;
