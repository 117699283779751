import '@gr/translations/lib/locale-data/en';
import '@gr/translations/lib/locale-data/pl';
import '@gr/translations/lib/locale-data/fr';
import { fetchSpaces, setConfig } from '@gr/translations';

const localeDataSource = {
  en: () => Promise.resolve([]),
  pl: () => Promise.resolve([]),
  fr: () => Promise.resolve([]),
  de: () => import(/* webpackChunkName: "locale-data-de" */ '@gr/translations/lib/locale-data/de'),
  es: () => import(/* webpackChunkName: "locale-data-es" */ '@gr/translations/lib/locale-data/es'),
  gb: () => import(/* webpackChunkName: "locale-data-gb" */ '@gr/translations/lib/locale-data/gb'),
  pt: () => import(/* webpackChunkName: "locale-data-pt" */ '@gr/translations/lib/locale-data/pt'),
  br: () => import(/* webpackChunkName: "locale-data-br" */ '@gr/translations/lib/locale-data/br'),
  ru: () => import(/* webpackChunkName: "locale-data-ru" */ '@gr/translations/lib/locale-data/ru'),
  it: () => import(/* webpackChunkName: "locale-data-it" */ '@gr/translations/lib/locale-data/it'),
  ja: () => import(/* webpackChunkName: "locale-data-ja" */ '@gr/translations/lib/locale-data/ja'),
  zh: () => import(/* webpackChunkName: "locale-data-zh" */ '@gr/translations/lib/locale-data/zh'),
  sv: () => import(/* webpackChunkName: "locale-data-sv" */ '@gr/translations/lib/locale-data/sv'),
  no: () => import(/* webpackChunkName: "locale-data-no" */ '@gr/translations/lib/locale-data/no'),
  ms: () => import(/* webpackChunkName: "locale-data-ms" */ '@gr/translations/lib/locale-data/ms'),
  th: () => import(/* webpackChunkName: "locale-data-th" */ '@gr/translations/lib/locale-data/th'),
  ko: () => import(/* webpackChunkName: "locale-data-ko" */ '@gr/translations/lib/locale-data/ko'),
  tr: () => import(/* webpackChunkName: "locale-data-tr" */ '@gr/translations/lib/locale-data/tr'),
  ro: () => import(/* webpackChunkName: "locale-data-ro" */ '@gr/translations/lib/locale-data/ro'),
  hu: () => import(/* webpackChunkName: "locale-data-hu" */ '@gr/translations/lib/locale-data/hu'),
  he: () => import(/* webpackChunkName: "locale-data-he" */ '@gr/translations/lib/locale-data/he'),
  vi: () => import(/* webpackChunkName: "locale-data-vi" */ '@gr/translations/lib/locale-data/vi'),
  id: () => import(/* webpackChunkName: "locale-data-id" */ '@gr/translations/lib/locale-data/id'),
  nl: () => import(/* webpackChunkName: "locale-data-nl" */ '@gr/translations/lib/locale-data/nl'),
  da: () => import(/* webpackChunkName: "locale-data-da" */ '@gr/translations/lib/locale-data/da'),
  ie: () => import(/* webpackChunkName: "locale-data-ga" */ '@gr/translations/lib/locale-data/ie'),
  mx: () => import(/* webpackChunkName: "locale-data-mx" */ '@gr/translations/lib/locale-data/mx'),
};

export function loadLocaleData(locale: string): Promise<void> {
  return localeDataSource?.[locale] && localeDataSource[locale]();
}

const localePolyfill = {
  en: () => import(/* webpackChunkName: "locale-data-polyfill-en" */ '@gr/translations/lib/locale-data/en.polyfill'),
  gb: () => import(/* webpackChunkName: "locale-data-polyfill-gb" */ '@gr/translations/lib/locale-data/gb.polyfill'),
  pl: () => import(/* webpackChunkName: "locale-data-polyfill-pl" */ '@gr/translations/lib/locale-data/pl.polyfill'),
  de: () => import(/* webpackChunkName: "locale-data-polyfill-de" */ '@gr/translations/lib/locale-data/de.polyfill'),
  es: () => import(/* webpackChunkName: "locale-data-polyfill-es" */ '@gr/translations/lib/locale-data/es.polyfill'),
  fr: () => import(/* webpackChunkName: "locale-data-polyfill-fr" */ '@gr/translations/lib/locale-data/fr.polyfill'),
  pt: () => import(/* webpackChunkName: "locale-data-polyfill-pt" */ '@gr/translations/lib/locale-data/pt.polyfill'),
  br: () => import(/* webpackChunkName: "locale-data-polyfill-br" */ '@gr/translations/lib/locale-data/br.polyfill'),
  ru: () => import(/* webpackChunkName: "locale-data-polyfill-ru" */ '@gr/translations/lib/locale-data/ru.polyfill'),
  it: () => import(/* webpackChunkName: "locale-data-polyfill-it" */ '@gr/translations/lib/locale-data/it.polyfill'),
  ja: () => import(/* webpackChunkName: "locale-data-polyfill-ja" */ '@gr/translations/lib/locale-data/ja.polyfill'),
  zh: () => import(/* webpackChunkName: "locale-data-polyfill-zh" */ '@gr/translations/lib/locale-data/zh.polyfill'),
  sv: () => import(/* webpackChunkName: "locale-data-polyfill-sv" */ '@gr/translations/lib/locale-data/sv.polyfill'),
  no: () => import(/* webpackChunkName: "locale-data-polyfill-no" */ '@gr/translations/lib/locale-data/no.polyfill'),
  ms: () => import(/* webpackChunkName: "locale-data-polyfill-ms" */ '@gr/translations/lib/locale-data/ms.polyfill'),
  th: () => import(/* webpackChunkName: "locale-data-polyfill-th" */ '@gr/translations/lib/locale-data/th.polyfill'),
  ko: () => import(/* webpackChunkName: "locale-data-polyfill-ko" */ '@gr/translations/lib/locale-data/ko.polyfill'),
  tr: () => import(/* webpackChunkName: "locale-data-polyfill-tr" */ '@gr/translations/lib/locale-data/tr.polyfill'),
  ro: () => import(/* webpackChunkName: "locale-data-polyfill-ro" */ '@gr/translations/lib/locale-data/ro.polyfill'),
  hu: () => import(/* webpackChunkName: "locale-data-polyfill-hu" */ '@gr/translations/lib/locale-data/hu.polyfill'),
  he: () => import(/* webpackChunkName: "locale-data-polyfill-he" */ '@gr/translations/lib/locale-data/he.polyfill'),
  vi: () => import(/* webpackChunkName: "locale-data-polyfill-vi" */ '@gr/translations/lib/locale-data/vi.polyfill'),
  id: () => import(/* webpackChunkName: "locale-data-polyfill-id" */ '@gr/translations/lib/locale-data/id.polyfill'),
  nl: () => import(/* webpackChunkName: "locale-data-polyfill-nl" */ '@gr/translations/lib/locale-data/nl.polyfill'),
  da: () => import(/* webpackChunkName: "locale-data-polyfill-da" */ '@gr/translations/lib/locale-data/da.polyfill'),
  ie: () => import(/* webpackChunkName: "locale-data-polyfill-ga" */ '@gr/translations/lib/locale-data/ie.polyfill'),
  mx: () => import(/* webpackChunkName: "locale-data-polyfill-mx" */ '@gr/translations/lib/locale-data/mx.polyfill'),
};

export function loadIntlPolyfill(locale: string): Promise<void> {
  if (!window.Intl || !window.Intl.PluralRules) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).Intl = window.Intl || {};
    return localePolyfill?.[locale] && localePolyfill[locale]();
  }
}

export function initTranslations(locale: string, spaces: string[]): Promise<void> {
  const isSandbox = process.env.GR_SANDBOX === 'enabled';
  setConfig({
    locale,
    apiUrl: isSandbox ? 'https://app.local.gr-dev.me/panel-api/translations/panel' : '/panel-api/translations/panel',
    useETag: !isSandbox,
  });

  return Promise.all([loadIntlPolyfill(locale), loadLocaleData(locale), fetchSpaces(spaces)]).then();
}
