import styled from 'styled-components';
import { ITheme } from '../styles/theme';

export interface ILanguagesListProps {
  width: number;
}

export const LanguagesList = styled.div<ILanguagesListProps & { theme?: ITheme }>`
  width: ${({ width }) => width}px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  font-size: ${({ theme }) => theme.font.size.small}px;
  background: rgba(16, 22, 29, 0.9);
  border-radius: ${({ theme }) => theme.borderRadius.primary}px;
  color: ${({ theme }) => theme.color.text.light};
`;
